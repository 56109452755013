import React from "react";

function ProductChoresSvg({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.125 31H3.875C2.80534 31 1.89209 30.6216 1.13525 29.8647C0.378418 29.1079 0 28.1947 0 27.125V7.75C0 6.68034 0.378418 5.76709 1.13525 5.01025C1.89209 4.25342 2.80534 3.875 3.875 3.875H5.8125V1.9375C5.8125 1.39258 5.99919 0.933431 6.37256 0.560059C6.74593 0.186686 7.20508 0 7.75 0C8.29492 0 8.75407 0.186686 9.12744 0.560059C9.50081 0.933431 9.6875 1.39258 9.6875 1.9375V3.875H13.5625V1.9375C13.5625 1.39258 13.7492 0.933431 14.1226 0.560059C14.4959 0.186686 14.9551 0 15.5 0C16.0449 0 16.5041 0.186686 16.8774 0.560059C17.2508 0.933431 17.4375 1.39258 17.4375 1.9375V3.875H21.3125V1.9375C21.3125 1.39258 21.4992 0.933431 21.8726 0.560059C22.2459 0.186686 22.7051 0 23.25 0C23.7949 0 24.2541 0.186686 24.6274 0.560059C25.0008 0.933431 25.1875 1.39258 25.1875 1.9375V3.875H27.125C28.1947 3.875 29.1079 4.25342 29.8647 5.01025C30.6216 5.76709 31 6.68034 31 7.75V27.125C31 28.1947 30.6216 29.1079 29.8647 29.8647C29.1079 30.6216 28.1947 31 27.125 31ZM24.6274 12.1548C24.2541 11.7814 23.8101 11.5947 23.2954 11.5947C22.7808 11.5947 22.3317 11.7764 21.9482 12.1396L13.5625 20.5557L9.02148 16.0146C8.63802 15.6514 8.18896 15.4697 7.67432 15.4697C7.15967 15.4697 6.71566 15.6564 6.34229 16.0298C5.96891 16.4032 5.78223 16.8472 5.78223 17.3618C5.78223 17.8765 5.97396 18.3154 6.35742 18.6787L11.8975 24.2188C12.0186 24.4408 12.0993 24.582 12.1396 24.6426C12.5231 25.0059 12.9974 25.1774 13.5625 25.1572C14.1074 25.1774 14.5716 25.0059 14.9551 24.6426C14.9954 24.582 15.0762 24.4408 15.1973 24.2188L24.6426 14.8037C25.0059 14.4404 25.1875 14.0015 25.1875 13.4868C25.1875 12.9722 25.0008 12.5282 24.6274 12.1548Z"
        fill="white"
      />
    </svg>
  );
}

export default ProductChoresSvg;
