import React from "react";

function ProductPeople({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33342 14.668C8.54548 14.668 7.76527 14.5128 7.03731 14.2112C6.30936 13.9097 5.64793 13.4678 5.09077 12.9106C4.53362 12.3535 4.09167 11.692 3.79014 10.9641C3.48861 10.2361 3.33341 9.4559 3.33341 8.66797C3.33341 7.88004 3.48861 7.09982 3.79014 6.37187C4.09167 5.64391 4.53362 4.98248 5.09077 4.42533C5.64793 3.86818 6.30936 3.42622 7.03731 3.12469C7.76527 2.82316 8.54548 2.66797 9.33342 2.66797C10.9247 2.66797 12.4508 3.30011 13.5761 4.42533C14.7013 5.55055 15.3334 7.07667 15.3334 8.66797C15.3334 10.2593 14.7013 11.7854 13.5761 12.9106C12.4508 14.0358 10.9247 14.668 9.33342 14.668ZM23.3334 20.0013C21.9189 20.0013 20.5624 19.4394 19.5622 18.4392C18.562 17.439 18.0001 16.0825 18.0001 14.668C18.0001 13.2535 18.562 11.8969 19.5622 10.8967C20.5624 9.89654 21.9189 9.33464 23.3334 9.33464C24.7479 9.33464 26.1045 9.89654 27.1047 10.8967C28.1048 11.8969 28.6667 13.2535 28.6667 14.668C28.6667 16.0825 28.1048 17.439 27.1047 18.4392C26.1045 19.4394 24.7479 20.0013 23.3334 20.0013ZM23.3334 21.3346C24.9247 21.3346 26.4508 21.9668 27.5761 23.092C28.7013 24.2172 29.3334 25.7433 29.3334 27.3346V28.0013H17.3334V27.3346C17.3334 25.7433 17.9656 24.2172 19.0908 23.092C20.216 21.9668 21.7421 21.3346 23.3334 21.3346ZM9.33342 16.0013C10.2089 16.0013 11.0758 16.1737 11.8846 16.5088C12.6935 16.8438 13.4284 17.3349 14.0475 17.9539C14.6665 18.573 15.1576 19.3079 15.4926 20.1167C15.8276 20.9256 16.0001 21.7925 16.0001 22.668V28.0013H2.66675V22.668C2.66675 20.8999 3.36913 19.2042 4.61937 17.9539C5.86961 16.7037 7.5653 16.0013 9.33342 16.0013Z"
        fill="white"
      />
    </svg>
  );
}

export default ProductPeople;
