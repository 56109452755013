import React from "react";

function IntroThunderSvg({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55999 11.7178C5.38424 15.2173 6.89304 18.552 7.37703 22.139C7.55849 23.4838 11.621 22.0029 12.8568 21.6221C13.2965 21.4867 14.6548 20.9774 15.0698 21.1756C15.7277 21.4898 16.2276 22.9832 16.5669 23.5753C17.8187 25.7599 19.0706 27.9445 20.3224 30.1291C20.5525 30.5305 20.7826 30.932 21.0126 31.3335C21.5107 32.2027 21.202 28.8488 21.1487 28.4593C21.0199 27.5194 21.1283 26.5606 21.0285 25.6166C20.9327 24.7109 20.5449 23.6371 21.0479 22.7515C21.4909 21.9717 21.1612 20.3533 21.1872 19.4575C21.2132 18.559 21.3593 17.7539 21.6254 16.8713C22.0119 15.5897 23.134 12.4117 20.8341 12.352C19.5123 12.3178 18.1351 12.1318 16.7812 12.3538C16.0194 12.4788 15.5086 12.4489 14.8097 12.8494C13.8627 13.3921 13.8955 12.946 14.0684 12.0346C14.4303 10.1279 15.0145 8.268 14.7694 6.34305C14.6424 5.34549 14.25 4.21818 14.4866 3.18997C14.7078 2.22853 14.8173 1.29223 13.5238 1.53638C11.6773 1.8849 10.0182 2.71552 8.2508 3.31841C7.12701 3.70174 6.13434 4.56853 4.97907 4.78965C4.54004 4.87368 3.70522 4.96148 3.33068 5.15768C2.89367 5.38661 2.3968 5.62882 1.99984 5.9203C1.42056 6.34566 2.40791 7.48346 2.66488 7.93189C3.19999 8.86572 3.27087 9.94684 3.79388 10.8595"
        stroke="#17D1BC"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IntroThunderSvg;
