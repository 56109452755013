import React from "react";

export default function UniCoinSvg({ className, onClick, clr }) {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#FDCC03" d="M 479.564 251.056 C 479.564 379.74 375.245 484.06 246.561 484.06 C 117.877 484.06 13.557 379.74 13.557 251.056 C 13.557 122.372 117.877 18.053 246.561 18.053 C 375.245 18.053 479.564 122.372 479.564 251.056 Z M 167.429 259.101 L 167.429 210.486 L 248.546 149.403 L 326.698 208.253 L 326.698 257.754 L 247.958 198.461 L 167.429 259.101 Z M 366.654 287.842 C 366.925 282.747 367.061 277.427 367.061 271.883 L 367.061 238.648 L 382.975 250.632 L 434.487 250.632 L 367.061 199.859 L 367.061 110.186 L 326.698 110.186 L 326.698 169.464 L 247.958 110.171 L 167.429 170.811 L 167.429 110.186 L 127.067 110.186 L 127.067 201.205 L 61.428 250.632 L 114.116 250.632 L 127.067 240.879 L 127.067 271.883 C 127.067 277.891 127.226 283.643 127.543 289.136 L 61.428 338.922 L 114.116 338.922 L 132.752 324.888 C 134.387 331.172 136.376 336.889 138.722 342.043 C 146.477 359.135 159.448 372.236 177.634 381.26 C 195.818 390.285 219.218 394.818 247.789 394.818 C 277.177 394.818 300.94 389.62 319.08 379.265 C 337.175 368.908 349.691 355.434 356.674 338.882 C 358.677 334.073 360.393 328.776 361.82 322.991 L 382.975 338.922 L 434.487 338.922 L 366.654 287.842 Z M 325.533 295.667 C 323.172 317.856 317.23 333.58 307.742 342.875 C 295.09 355.226 273.912 361.422 244.252 361.422 C 226.883 361.422 212.19 358.304 200.218 352.066 C 188.2 345.826 179.719 336.927 174.822 325.407 C 171.864 318.515 169.799 309.345 168.618 297.88 L 248.546 237.693 L 325.533 295.667 Z" transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, 0)"/>

      
    </svg>
  );
}
